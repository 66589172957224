<template>
    <div class="summary d-flex flex-column">
        <h5 class="mb-5">Report Configuration</h5>
        <div class="config-container mb-5">
            <AonCard>
                <div class="content-holder">
                    <AonInput
                        v-model="reportName"
                        label="Report Name (Optional)"
                        placeholder="Report Name"
                        @keyup="setReportName()"
                    />
                </div>
            </AonCard>
        </div>
        <div class="summary-container flex-grow-1 d-flex flex-column">
            <div class="type-holder pa-5">
                <p class="small uppercase gold--text bold">Report Type</p>
                <h5 class="white--text">
                    {{ reportGenerationStore.reportSelections[0].selectionLabel }}
                </h5>
            </div>
            <div class="company-holder white pa-5">
                <p class="small uppercase grey03--text bold">Target Company</p>
                <h5 class="castle-moat--text">
                    {{ reportGenerationStore.reportSelections[1].selectionLabel }}
                </h5>
            </div>
            <div class="tab-holder white">
                <AonTabs
                    class="light"
                    :current-tab="currentTab"
                    :tabs="tabData"
                    bottom-border
                    @tab-change="tabChange"
                />
            </div>
            <div v-if="currentTab === 1" class="tab-content-tech h-full flex-grow-1">
                <MoatTable
                    class="w-full h-full"
                    :class="themeClass"
                    :column-defs="colDefs"
                    :suppress-auto-size="true"
                    :tooltip-interaction="true"
                    :tooltip-mouse-track="true"
                    :tooltip-show-delay="500"
                    tooltip-show-mode="whenTruncated"
                    :autoGroupColumnDef="autoGroupColumnDef"
                    :groupRemoveLowestSingleChildren="true"
                    :groupSelectsChildren="true"
                    :groupDisplayType="groupDisplayType"
                    :treeData="true"
                    :getDataPath="getDataPath"
                    :autoSizeStrategy="sizingStrategy"
                    :excludeChildrenWhenTreeDataFiltering="true"
                    :row-data="reportGenerationStore.reportSelections[2].value"
                    @grid-ready="gridInit"
                />
            </div>
            <div v-if="currentTab === 2" class="tab-content-tech h-full" style="position: relative">
                <MoatTable
                    class="w-full h-full"
                    :class="themeClass"
                    :column-defs="contenderColDefs"
                    :row-data="reportGenerationStore.reportSelections[3].value"
                    :tooltip-interaction="true"
                    :tooltip-mouse-track="true"
                    :tooltip-show-delay="500"
                    tooltip-show-mode="whenTruncated"
                    :autoSizeStrategy="sizingStrategy"
                    :getRowClass="getRowClass"
                    @grid-ready="gridInit"
                />
                <div
                    v-if="allowFill"
                    class="fill-contender-notification w-full pa-5 d-flex align-center justify-content-between"
                >
                    <div class="text-holder">
                        <p>
                            You have
                            <span style="font-weight: 700"
                                >{{
                                    reportGenerationStore.reportSelections[3].value.length
                                }}
                                selected contenders out of a maximum of 8</span
                            >
                        </p>
                        <p class="mt-2">
                            Would you like the system to populate remaining
                            {{ fillRemaining }} available slots?
                        </p>
                    </div>
                    <AonToggleSwitch
                        label="Auto-fill remaining slots"
                        label-end
                        @switch="toggleSwitch"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, inject, ref, onBeforeMount } from 'vue'
import { useReportGenerationStore } from '@/stores'

import { useRouter } from 'vue-router'
import { useAuth } from '@/auth/authPlugin'
import { useI18n } from 'vue-i18n'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { dateComparator } from '@/components/moatTable/helpers/compare.js'
import { formatTpeRange } from '@/lib/financial.js'

const eventBus = inject('eventBus')
const router = useRouter()
const { user } = useAuth()
const filters = inject('filters')
const { t } = useI18n()
const reportGenerationStore = useReportGenerationStore()

const reportName = ref('')
const currentTab = ref(1)
const tabData = ref([
    {
        text: 'TECHNOLOGY AREAS',
        id: 1,
        appendCount: reportGenerationStore.reportSelections[2].value.length,
    },
    {
        text: 'CONTENDERS',
        id: 2,
        appendCount: reportGenerationStore.reportSelections[3].value.length,
    },
])
const gridApi = ref(null)
const getDataPath = ref(null)
const sizingStrategy = ref({
    type: 'fitProvidedWidth',
    width: 2000,
})
const colDefs = ref([
    {
        rowGroup: true,
        hide: true,
        field: 'parentDisplayName',
        tooltipField: 'parentDisplayName',
    },
    reportGenerationStore.selectionSource === 'moat2Product'
        ? {
              headerComponent: 'MTCustomColHeader',
              headerComponentParams: {
                  tooltipDefintion: t('productAlignment.definitionTooltips.innImpliedPE'),
                  headerName: 'II P/E',
              },
              field: 'targetTpe',
              headerTooltip: 'Innovation Implied P/E',
              flex: 1,
              valueFormatter: (p) => {
                  if (p.data) {
                      return formatTpeRange(
                          p.data.targetTpe,
                          p.data.targetTpeLb,
                          p.data.targetTpeUb
                      )
                  }
              },
              sort: 'desc',
          }
        : { hide: true },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innImpliedPE'),
            headerName: 'Contender II P/E',
        },
        field: 'tpe',
        headerTooltip: 'Contender Innovation Implied P/E',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return formatTpeRange(p.data.tpe, p.data.tpeLb, p.data.tpeUb)
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.opportunity'),
            headerName: 'Momentum',
        },
        field:
            reportGenerationStore.selectionSource === 'moat2Product'
                ? 'targetOpportunity'
                : 'opportunity',
        headerTooltip: 'Momentum',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return reportGenerationStore.selectionSource === 'moat2Product'
                    ? p.data.targetOpportunity.toFixed(0)
                    : p.data.opportunity.toFixed(0)
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.coverage'),
            headerName: 'Scope',
        },
        field:
            reportGenerationStore.selectionSource === 'moat2Product'
                ? 'targetCoverage'
                : 'coverage',
        headerTooltip: 'Scope',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return reportGenerationStore.selectionSource === 'moat2Product'
                    ? p.data.targetCoverage.toFixed(0)
                    : p.data.coverage.toFixed(0)
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion:
                reportGenerationStore.selectionSource === 'moat2Product'
                    ? t('productAlignment.definitionTooltips.patentCountTotal')
                    : t('productAlignment.definitionTooltips.totalPatentCount'),
            headerName:
                reportGenerationStore.selectionSource === 'moat2Product'
                    ? `${reportGenerationStore.reportSelections[1].selectionLabel} Patent Count / Total`
                    : `Total Patent Count`,
        },
        field: 'totalPatentCount',
        valueFormatter: (p) => {
            if (p.data) {
                if (reportGenerationStore.selectionSource === 'moat2Product') {
                    return `${p.data.targetEntityPatentCount.toLocaleString()} / ${p.data.totalPatentCount.toLocaleString()}`
                } else {
                    return `${p.data.totalPatentCount.toLocaleString()}`
                }
            }
        },
        headerTooltip:
            reportGenerationStore.selectionSource === 'moat2Product'
                ? `${reportGenerationStore.reportSelections[1].selectionLabel} Patent Count / Total`
                : `Total Patent Count`,
        flex: 1,
    },
    reportGenerationStore.selectionSource === 'moat2Product'
        ? {
              headerComponent: 'MTCustomColHeader',
              headerComponentParams: {
                  tooltipDefintion: t('productAlignment.definitionTooltips.dominance'),
                  headerName: `${reportGenerationStore.reportSelections[1].selectionLabel} Dominance`,
              },
              valueGetter: (p) => {
                  if (p.data) {
                      return p.data.patentShare
                  }
              },
              valueFormatter: (p) => {
                  if (p.data) {
                      return p.data.patentShare + '%'
                  }
              },
              headerTooltip: `${reportGenerationStore.reportSelections[1].selectionLabel} Dominance`,
              flex: 1,
          }
        : { hide: true },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion:
                reportGenerationStore.selectionSource === 'moat2Product'
                    ? t('productAlignment.definitionTooltips.avgFilingDate')
                    : t('productAlignment.definitionTooltips.avgFilingDateConcept'),
            headerName: 'Average Filing Date',
        },
        valueGetter: (p) => {
            if (p.data) {
                return filters.toUTCString(p.data.averageFilingDt)
            }
        },
        headerTooltip: `Average Filing Date`,
        comparator: dateComparator,
        flex: 1,
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion:
                reportGenerationStore.selectionSource === 'moat2Product'
                    ? t('productAlignment.definitionTooltips.contenders')
                    : t('productAlignment.definitionTooltips.contendersConcept'),
            headerName: 'Contenders',
        },
        valueGetter: (p) => {
            if (p.data) {
                return p.data.competitorCount
            }
        },
        headerTooltip: `Contenders`,
        flex: 1,
    },
])
const contenderColDefs = ref([
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.companyName'),
            headerName: 'Company Name',
        },
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        field: 'aonEntityName',
        headerTooltip: 'Company Name',
        width: 300,
    },
    {
        field: 'competitorRank',
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.competitorRank.toLocaleString()}`
            }
        },
        headerTooltip: 'Rank',
        width: 100,
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innImpliedPE'),
            headerName: 'II P/E',
        },
        field: 'tpe',
        headerTooltip: 'Innovation Implied P/E',
        width: 100,
        valueFormatter: (p) => {
            if (p.data) {
                return formatTpeRange(p.data.tpe, p.data.tpeLb, p.data.tpeUb)
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innovationScore'),
            headerName: 'Innovation Score',
        },
        field: 'powerScore',
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.powerScore.toFixed(0)).toLocaleString()}`
            }
        },
        headerTooltip: 'Innovation Score',
        width: 150,
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.opportunity'),
            headerName: 'Momentum',
        },
        field: 'opportunity',
        valueFormatter: (p) => {
            if (p.data) {
                return Math.round(p.data.opportunity)
            }
        },
        headerTooltip: 'Momentum',
        width: 150,
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.coverage'),
            headerName: 'Scope',
        },
        field: 'coverage',
        headerTooltip: 'Scope',
        width: 100,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.coverage.toFixed(0)
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.percentOfPortfolio'),
            headerName: 'Percentage of Portfolio',
        },
        field: 'portfolioFocusPercentage',
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.portfolioFocusPercentage.toFixed(2)}%`
            }
        },
        headerTooltip: 'Percentage of Portfolio',
        width: 200,
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.fiveYear'),
            headerName: 'Five Year Expire Percent',
        },
        field: 'fiveYearExpirePercent',
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.fiveYearExpirePercent}%`
            }
        },
        headerTooltip: 'Five Year Expire Percent',
        width: 200,
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.priority'),
            headerName: 'Priority Date',
        },
        valueGetter: (p) => {
            if (p.data) {
                return filters.toUTCString(p.data.entryPriorityDt) === '1-1-1'
                    ? '---'
                    : filters.toUTCString(p.data.entryPriorityDt)
            }
        },
        headerTooltip: `Priority Date`,
        comparator: dateComparator,
        width: 150,
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.avgFilingDateContender'),
            headerName: 'Average Filing Date',
        },
        valueGetter: (p) => {
            if (p.data) {
                return filters.toUTCString(p.data.averageFilingDt) === '1-1-1'
                    ? '---'
                    : filters.toUTCString(p.data.averageFilingDt)
            }
        },
        headerTooltip: `Average Filing Date`,
        comparator: dateComparator,
        width: 150,
    },
])
const autoGroupColumnDef = ref(null)
const groupDisplayType = ref(null)
const themeClass = ref('ag-theme-quartz')

onBeforeMount(() => {
    defineTableGroups()
    getDataPath.value = (data) => {
        return data.nodeHierarchy.map((item) => item.displayName)
    }
    groupDisplayType.value = 'singleColumn'
})

const allowFill = computed(() => {
    return (
        reportGenerationStore.reportSelections[3].value.length < 8 &&
        reportGenerationStore.reportTypeSelected === 'compAnalysis'
    )
})

const fillRemaining = computed(() => {
    return 8 - reportGenerationStore.reportSelections[3].value.length
})

const gridInit = (params) => {
    gridApi.value = params.api
    params.api.hideOverlay()
    params.api.expandAll()
}

const tabChange = (tab) => {
    currentTab.value = tab.id
}

const defineTableGroups = () => {
    autoGroupColumnDef.value = {
        minWidth: 450,
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion:
                reportGenerationStore.selectionSource === 'moat2Product'
                    ? t('productAlignment.definitionTooltips.productsAndTech')
                    : 'Technologies',
            headerName:
                reportGenerationStore.selectionSource === 'moat2Product'
                    ? 'Products & Technologies'
                    : 'Technologies',
        },
        field: 'displayName',
        tooltipField: 'displayName',
        suppressMovable: true,
        lockPosition: 'left',
    }
}

const getRowClass = (params) => {
    if (params.data.aonEntityName === reportGenerationStore.reportSelections[0].selectionLabel) {
        return 'target-entity'
    } else {
        return ''
    }
}

const toggleSwitch = (params) => {
    reportGenerationStore.setShouldFillContenders(params.value)
}

const setReportName = () => {
    reportGenerationStore.reportName = reportName.value
}
</script>

<style lang="scss" scoped>
.summary {
    height: 100%;
    width: 100%;

    .config-container {
    }

    .type-holder {
        background: $knightsCloak60;
        border-radius: 8px 8px 0 0;
    }

    .company-holder {
        border-bottom: 1px solid $grey04;
    }

    .fill-contender-notification {
        width: calc(100% - 40px);
        background: $warningBackground;
        border-radius: 4px;

        position: absolute;
        bottom: 20px;
        left: 20px;
    }

    :deep(.a-tabs) {
        .count-item {
            background: $castleMoat;
            color: white !important;
            border-radius: px !important;
            padding: 4px 6px !important;
            font-size: 16px;
        }
    }

    :deep(.a-toggle-switch) {
        label {
            border-radius: 6px !important;
            width: 40px;
            height: 23px;

            &:after {
                // width: 18px;
                height: 17px;
                border-radius: 4px !important;
            }
        }
    }
}
</style>
