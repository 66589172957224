<template>
    <div class="cell-wrapper d-flex align-items-center" style="width: fit-content">
        <div class="link-wrapper h-full d-flex align-items-center" style="width: fit-content">
            <div
                v-if="params.allowNewTab && params.tabOnLeft"
                class="new-tab-icon mr-3"
                style="cursor: pointer"
                v-tooltip="{
                    content: 'Open company in a new tab',
                    placement: 'top',
                    distance: '-5',
                }"
            >
                <a :href="entityRouteLink(getEntityId())" target="_blank">
                    <font-awesome-icon
                        class="grey01--text"
                        icon="fas fa-arrow-up-right-from-square"
                    />
                </a>
            </div>
            <div
                class="ultimate-parent mr-3"
                v-if="params.data.ultimateEntityName"
                v-tooltip="{
                    content: `Ultimate Parent - ${params.data.ultimateEntityName}`,
                    placement: 'top',
                    distance: '-5',
                }"
            >
                <font-awesome-icon class="grey01--text" icon="fas fa-buildings" />
            </div>
            <p class="popper-hook small link overflow-ellipsis" @click="showPopover = true">
                {{ params.value }}
            </p>
            <div
                v-if="params.allowNewTab && !params.tabOnLeft"
                class="new-tab-icon"
                style="cursor: pointer"
                v-tooltip="{ content: 'Open company in a new tab' }"
            >
                <a :href="entityRouteLink(getEntityId())" target="_blank">
                    <font-awesome-icon
                        class="grey01--text mx-3"
                        icon="fas fa-arrow-up-right-from-square"
                    />
                </a>
            </div>
        </div>
        <div
            v-if="params.data.stockTicker"
            class="ticker d-inline-flex align-items-center ml-2 pa-1 grey05"
        >
            <p class="small">{{ params.data.stockTicker }}</p>
        </div>
        <CompanyPopover
            :show-popover="showPopover"
            :passed-company-details="params.data"
            @close-popover="showPopover = false"
        />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import CompanyPopover from '../helpers/CompanyPopover.vue'
import { entityRouteLink } from '../../router/utils'

const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
})

const emit = defineEmits(['remove-selection'])

onMounted(() => {})

const getEntityId = () =>
    props.params.data.aonEntityPk || props.params.data.aon_entity_pk || props.params.data.entityPk

const showPopover = ref(false)
</script>

<style lang="scss" scoped>
.ticker {
    border-radius: 4px;

    transition: all ease-in-out 0.3s;

    span {
        color: $grey01;
        transition: all ease-in-out 0.3s;
    }
}
</style>
