<template>
    <div class="output-competitor-selection" :class="{ normalize: props.reportStep }">
        <AonModal title="" message="" col-width="10" @close="hideModal()">
            <div class="competitor-selection-holder pa-5 d-flex flex-wrap">
                <div class="tables-parent d-flex w-full">
                    <div class="selected-competitors-holder">
                        <h6 class="border">Arena Contenders</h6>
                        <p class="pb-3 border">
                            Select Companies you would like to include in the data visualizations
                            and report.
                        </p>
                        <div class="table-quick-filters d-flex pb-5 border">
                            <AonDropdown
                                class="mr-5"
                                label="Filter By"
                                :items="quickFilterOptions"
                                :init-selection="quickFilterOptions[0]"
                                style="width: 300px"
                                @select-option="changeQuickFilterValue"
                            />
                            <AonInput
                                class="w-full pr-5"
                                v-model="searchTerm"
                                search-input
                                label="Search Contender Table"
                                placeholder="Search Contender Table"
                                @keyup="searchTable()"
                                @clear="clearSearch()"
                            />
                        </div>
                        <MoatTable
                            class="border pr-5"
                            :class="[themeClass, { 'disabled-checkbox': setDisabledCheckboxes }]"
                            style="width: 100%; height: 100%"
                            :column-defs="arenaContenderColDef"
                            :row-data="groomedData"
                            :tooltip-interaction="true"
                            :tooltip-mouse-track="true"
                            :tooltip-show-delay="500"
                            :sideBar="sideBar"
                            tooltip-show-mode="whenTruncated"
                            :autoSizeStrategy="sizingStrategy"
                            :suppress-call-focus="true"
                            :row-selection="rowSelection"
                            :is-external-filter-present="shouldApplyQuickFilter"
                            :does-external-filter-pass="applyQuickFilter"
                            @row-selected="addSelection"
                            @grid-ready="gridInit"
                            :getRowId="getRowId"
                        />
                    </div>
                    <div class="selected-competitors-holder ml-5 selected-offset">
                        <h6 class="selected-header">Selected Contenders</h6>
                        <p class="mb-3">
                            {{ localTopCompetitors.length }} of {{ maxCompetitors }} Selected
                        </p>
                        <MoatTable
                            style="width: 100%; height: calc(100% + 90px)"
                            :class="themeClass"
                            :column-defs="selectedContenderColDef"
                            :row-data="localTopCompetitors"
                            :tooltip-interaction="true"
                            :tooltip-mouse-track="true"
                            :tooltip-show-delay="500"
                            :sideBar="sideBar"
                            tooltip-show-mode="whenTruncated"
                            :row-selection="rowSelectionSelected"
                            :autoSizeStrategy="sizingStrategy"
                            :getRowId="getRowId"
                        />
                    </div>
                </div>
                <div v-if="!props.reportStep" class="action-holder d-flex align-items-end w-full">
                    <AonButton
                        class="mr-5"
                        :class="{ disabled: localTopCompetitors.length === 0 }"
                        label="Apply"
                        @clicked="applySelections()"
                    />
                    <AonButton label="Cancel" type="ghost" @clicked="hideModal()" />
                </div>
            </div>
        </AonModal>
        <!-- <div class="tabs-holder" :class="{ disabled: !everythingDoneLoading }">
            <AonTabs
                class="light"
                :current-tab="currentTab"
                :tabs="tabData"
                bottom-border
                @tab-change="tabChange"
            />
        </div> -->
    </div>
</template>

<script setup>
// Global
import { onMounted, ref, inject, computed, watch, nextTick, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMoat2ProductStore, useReportGenerationStore } from '@/stores'
import { useFlag } from '@unleash/proxy-client-vue'

// local
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { dateComparator } from '@/components/moatTable/helpers/compare.js'
import { formatTpeRange } from '@/lib/financial.js'
import { cloneDeep } from 'lodash-es'

// injectors
const eventBus = inject('eventBus')
const { t } = useI18n()
const logger = inject('logger')
const filters = inject('filters')

const moat2ProductStore = useMoat2ProductStore()
const reportGenerationStore = useReportGenerationStore()

const enhancedContendersView = useFlag('ipAlpha.enhancedContendersFormatting')

// Refs/Props
const emit = defineEmits(['show-competitor-modal', 'refresh-arena-space'])
const props = defineProps({
    tableData: {
        type: Array,
        default: () => [],
    },
    allTableData: {
        type: Array,
        default: () => [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
    everythingDoneLoading: {
        type: Boolean,
        default: false,
    },
    activeFilter: {
        type: String,
        default: 'all',
    },
    reportStep: {
        type: Number,
        default: null,
    },
})

const loadingCompetitorData = ref(false)
const localAllTableData = ref([])
const localTopCompetitors = ref([])

const searchTerm = ref(null)
const gridApi = ref(null)
const sideBar = ref(null)
const quickFilterOptions = ref([
    {
        itemText: 'All',
    },
    {
        itemText: 'Public',
    },
    {
        itemText: 'Private',
    },
    {
        itemText: 'Gov/Edu',
    },
])
const rowSelection = ref({
    checkboxes: true,
})
const rowSelectionSelected = ref({
    enableClickSelection: true,
    checkboxes: false,
})
var externalFilterValue = 'All'

const themeClass = ref('ag-theme-quartz')
const sizingStrategy = ref(
    enhancedContendersView.value
        ? {}
        : {
              type: 'fitProvidedWidth',
              width: 2000,
          }
)
const getRowId = (params) => {
    if (params.data) {
        return String(params.data.aonEntityPk)
    } else {
        return String(params.aonEntityPk)
    }
}
const baseColDefs = ref(
    enhancedContendersView.value
        ? /* Updated contenders columns https://app.clickup.com/t/9014180224/MOAT-2212 */
          [
              {
                  field: 'competitorRank',
                  headerName: 'Rank',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${p.data.competitorRank.toLocaleString()}`
                      }
                  },
                  headerTooltip: 'Rank',
                  minWidth: 100,
                  flex: 1,
                  sortingOrder: ['asc', 'desc', null],
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.innovationScore'),
                      headerName: 'Innovation Score',
                  },
                  headerName: 'Innovation Score',
                  field: 'powerScore',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${parseInt(p.data.powerScore.toFixed(0)).toLocaleString()}`
                      }
                  },
                  headerTooltip: 'Innovation Score',
                  minWidth: 200,
                  flex: 1,
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.opportunity'),
                      headerName: 'Momentum',
                  },
                  headerName: 'Momentum',
                  field: 'opportunity',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return Math.round(p.data.opportunity)
                      }
                  },
                  headerTooltip: 'Momentum',
                  minWidth: 150,
                  flex: 1,
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.percentOfPortfolio'),
                      headerName: 'Focus',
                  },
                  headerName: 'Focus',
                  field: 'portfolioFocusPercentage',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${p.data.portfolioFocusPercentage.toFixed(0)}%`
                      }
                  },
                  headerTooltip: 'Focus',
                  minWidth: 120,
                  flex: 1,
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.innImpliedPE'),
                      headerName: 'II P/E',
                  },
                  headerName: 'II P/E',
                  field: 'tpe',
                  headerTooltip: 'Innovation Implied P/E',
                  minWidth: 120,
                  flex: 1,
                  valueFormatter: (p) => {
                      if (p.data) {
                          return formatTpeRange(p.data.tpe, p.data.tpeLb, p.data.tpeUb)
                      }
                  },
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.innovationStart'),
                      headerName: 'Innovation Start',
                  },
                  headerName: 'Innovation Start',
                  valueGetter: (p) => {
                      if (p.data) {
                          return filters.toUTCString(p.data.entryPriorityDt) === '1-1-1'
                              ? '---'
                              : new Date(p.data.entryPriorityDt).getFullYear()
                      }
                  },
                  field: 'entryPriorityDt',
                  headerTooltip: `Innovation Start`,
                  comparator: dateComparator,
                  minWidth: 200,
                  flex: 1,
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.fiveYear'),
                      headerName: 'Expiring Soon',
                  },
                  headerName: 'Expiring Soon',
                  field: 'fiveYearExpirePercent',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${p.data.fiveYearExpirePercent.toFixed(0)}%`
                      }
                  },
                  headerTooltip: 'Expiring Soon',
                  minWidth: 170,
                  flex: 1,
                  filter: 'agNumberColumnFilter',
              },
              {
                  field: 'hqCountryName',
                  headerName: 'HQ Country',
                  hide: true,
                  filter: 'agSetColumnFilter',
              },
              {
                  field: 'totalRevenue',
                  headerName: 'Revenue',
                  hide: true,
                  filter: 'agNumberColumnFilter',
              },
              {
                  field: 'employeeCount',
                  headerName: 'Employee Count',
                  hide: true,
                  filter: 'agSetColumnFilter',
              },
              {
                  field: 'priceToEarnings',
                  headerName: 'Price to Earnings',
                  hide: true,
                  filter: 'agNumberColumnFilter',
              },
          ]
        : /* Original contenders columns */ [
              {
                  field: 'competitorRank',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${p.data.competitorRank.toLocaleString()}`
                      }
                  },
                  headerTooltip: 'Rank',
                  width: 100,
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.innovationScore'),
                      headerName: 'Innovation Score',
                  },
                  field: 'powerScore',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${parseInt(p.data.powerScore.toFixed(0)).toLocaleString()}`
                      }
                  },
                  headerTooltip: 'Innovation Score',
                  width: 100,
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.opportunity'),
                      headerName: 'Momentum',
                  },
                  field: 'opportunity',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return Math.round(p.data.opportunity)
                      }
                  },
                  headerTooltip: 'Momentum',
                  width: 100,
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.percentOfPortfolio'),
                      headerName: 'Percentage of Portfolio',
                  },
                  field: 'portfolioFocusPercentage',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${p.data.portfolioFocusPercentage}%`
                      }
                  },
                  headerTooltip: 'Percentage of Portfolio',
                  width: 200,
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.fiveYear'),
                      headerName: 'Five Year Expire Percent',
                  },
                  field: 'fiveYearExpirePercent',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${p.data.fiveYearExpirePercent}%`
                      }
                  },
                  headerTooltip: 'Five Year Expire Percent',
                  width: 200,
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.priority'),
                      headerName: 'Priority Date',
                  },
                  valueGetter: (p) => {
                      if (p.data) {
                          return filters.toUTCString(p.data.entryPriorityDt)
                      }
                  },
                  headerTooltip: `Priority Date`,
                  comparator: dateComparator,
                  width: 150,
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t(
                          'productAlignment.definitionTooltips.avgFilingDateContender'
                      ),
                      headerName: 'Average Filing Date',
                  },
                  valueGetter: (p) => {
                      if (p.data) {
                          return filters.toUTCString(p.data.averageFilingDt)
                      }
                  },
                  headerTooltip: `Average Filing Date`,
                  comparator: dateComparator,
                  width: 150,
              },
          ]
)

const arenaContenderColDef = ref([
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.companyName'),
            headerName: 'Company Name',
        },
        headerName: 'Company Name',
        field: 'aonEntityName',
        headerTooltip: 'Company Name',
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        width: 200,
        minWidth: 350,
        cellClassRules: {
            'disabled-checkbox': (params) => {
                return localTopCompetitors.value.length >= maxCompetitors.value
            },
        },
        filter: 'agTextColumnFilter',
    },
    ...baseColDefs.value,
])
const selectedContenderColDef = ref([
    {
        cellRenderer: 'MTCustomCellCheckbox',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        cellClassRules: {
            'disabled-checkbox': (params) => {
                if (props.reportStep) {
                    return (
                        params.data.aonEntityPk ===
                        reportGenerationStore.getReportSelectionByStep(props.reportStep).value[0]
                            .aonEntityPk
                    )
                }
            },
        },
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.companyName'),
            headerName: 'Company Name',
        },
        headerName: 'Company Name',
        field: 'aonEntityName',
        headerTooltip: 'Company Name',
        width: 200,
        minWidth: 350,
        pinned: 'left',
        filter: 'agTextColumnFilter',
    },
    ...baseColDefs.value,
])

onMounted(() => {
    eventBus.on('remove-selection', (item) => {
        removeSelection(item)
    })
    if (!props.reportStep) {
        localTopCompetitors.value = cloneDeep(moat2ProductStore.productAlignmentTopCompetitors)
    } else {
        const techAreaStep = reportGenerationStore.getReportSelectionByStep(props.reportStep)
        localTopCompetitors.value = cloneDeep(techAreaStep.value)
    }
})

onBeforeUnmount(() => {
    eventBus.all.clear()
    if (!props.reportStep) {
        localTopCompetitors.value = cloneDeep(moat2ProductStore.productAlignmentTopCompetitors)
    } else {
        const techAreaStep = reportGenerationStore.getReportSelectionByStep(props.reportStep)
        localTopCompetitors.value = cloneDeep(techAreaStep.value)
    }
})

watch(
    () => props.allTableData,
    async (newVal, oldVal) => {
        if (newVal.length > 0) {
            localAllTableData.value = props.allTableData
        }
    }
)

watch(
    () => props.allTableData,
    (newVal, oldVal) => {
        if (
            newVal?.find((n) => n.isCompetitor) &&
            !quickFilterOptions.value.find((n) => n.itemText === 'Direct Competitors')
        ) {
            quickFilterOptions.value.splice(1, 0, { itemText: 'Direct Competitors' })
        }
    },
    { immediate: true }
)

const maxCompetitors = computed(() => {
    const reportType = reportGenerationStore.reportTypeSelected
    switch (reportType) {
        case 'compAnalysis':
            return 8
        case 'headToHead':
            return 4
        default:
            return 12
    }
})

const setDisabledCheckboxes = computed(() => {
    return localTopCompetitors.value.length >= maxCompetitors.value
})

const groomedData = computed(() => {
    var result = props.allTableData.filter(
        (item) =>
            !localTopCompetitors.value.some(
                (competitor) => competitor.aonEntityName === item.aonEntityName
            )
    )
    return result
})

const gridInit = (params) => {
    gridApi.value = params.api
    if (enhancedContendersView.value) {
        sideBar.value = {
            toolPanels: [
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                },
            ],
        }
    }
}

const hideModal = () => {
    emit('show-competitor-modal', false)
}

const addSelection = async (params) => {
    if (params.event && params.event.type === 'click') {
        if (localTopCompetitors.value.length < maxCompetitors.value) {
            localTopCompetitors.value.push(params.data)
            localTopCompetitors.value = localTopCompetitors.value.slice()

            if (props.reportStep) {
                let contenderStep = reportGenerationStore.getReportSelectionByStep(props.reportStep)
                contenderStep.value = cloneDeep(localTopCompetitors.value)
            }
        }
    }
}

const removeSelection = async (params) => {
    localTopCompetitors.value = localTopCompetitors.value.filter(
        (item) => item.aonEntityName !== params.data.aonEntityName
    )

    if (props.reportStep) {
        let contenderStep = reportGenerationStore.getReportSelectionByStep(props.reportStep)
        contenderStep.value = cloneDeep(localTopCompetitors.value)
    }
}

const applySelections = async () => {
    moat2ProductStore.productAlignmentTopCompetitors = cloneDeep(localTopCompetitors.value)
    setTimeout(() => {
        emit('refresh-arena-space')
        hideModal()
    }, 100)
}

// Quick filter
const shouldApplyQuickFilter = () => {
    // if externalFilterValue is not 'All', then we are filtering
    return externalFilterValue !== 'All'
}

const changeQuickFilterValue = (tab) => {
    externalFilterValue = tab.itemText
    setTimeout(() => {
        gridApi.value.onFilterChanged()
    }, 1000)
}

const applyQuickFilter = (node) => {
    if (node.data) {
        switch (externalFilterValue) {
            case 'Public':
                return node.data.entityType === 'Public Company'
            case 'Private':
                return (
                    node.data.entityType !== 'Government' &&
                    node.data.entityType !== 'College/University' &&
                    node.data.entityType !== 'Public Company'
                )
            case 'Gov/Edu':
                return (
                    node.data.entityType === 'Government' ||
                    node.data.entityType === 'College/University'
                )
            case 'Direct Competitors':
                return node.data.isCompetitor
            default:
                return true
        }
    }
    return true
}

// search filter
const searchTable = () => {
    gridApi.value.setGridOption('quickFilterText', searchTerm.value)
}

const clearSearch = () => {
    searchTerm.value = ''
    searchTable()
}
</script>

<style lang="scss" scoped>
.output-competitor-selection {
    &.normalize {
        height: 100%;
        :deep(.a-modal) {
            position: static !important;
            transform: none;

            .aon-container,
            .aon-row,
            .aon-col {
                width: 100% !important;
                max-width: none !important;
                height: 100% !important;
                margin: 0 !important;
                flex: none !important;
            }

            .background {
                display: none;

                .elevation-5 {
                    box-shadow: none !important;
                }
            }

            .container {
                max-height: none !important;
                height: 100% !important;
                box-shadow: none !important;
                overflow: inherit !important;
            }

            .a-modal-close {
                display: none;
                pointer-events: none;
            }

            .tables-parent {
                height: 86% !important;
            }
        }
    }

    .tables-parent {
        height: calc(100% - 210px);
    }

    .competitor-selection-holder {
        height: 100%;

        .selected-competitors-holder {
            width: 50%;
            height: 100%;

            .border {
                border-right: 1px solid $grey02;
            }
        }
    }

    :deep(.ag-selection-checkbox) {
        margin-right: -50px !important;
    }

    :deep(.disabled-checkbox) {
        .ag-selection-checkbox {
            opacity: 0.2;
            pointer-events: none;
        }

        .fa-circle-minus {
            opacity: 0.2;
            pointer-events: none;
        }
    }

    :deep(.a-modal) {
        .container {
            padding: 0 !important;
            border-radius: 12px !important;
            max-height: 800px !important;
            height: 80% !important;
        }
    }
}
</style>
