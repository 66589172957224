<template>
    <div class="competitors-output-table">
        <div class="table-quick-filters d-flex mb-5">
            <AonDropdown
                :key="filterUpdater"
                class="mr-5"
                :class="{ disabled: loadingWatchlists }"
                label="Filter By"
                :items="quickFilterOptions"
                :init-selection="quickFilterOptions[0]"
                style="width: 300px"
                @select-option="changeQuickFilterValue"
            />
            <AonInput
                style="width: 500px"
                v-model="searchTerm"
                search-input
                label="Search Contender Table"
                placeholder="Search Contender Table"
                @keyup="searchTable()"
                @clear="clearSearch()"
            />
        </div>
        <div class="table-holder">
            <AonCoverLoading :loading="loadingTable" title="Populating Competitors" />
            <MoatTable
                style="width: 100%; height: 600px"
                :key="`comp_table_${updater}`"
                :class="themeClass"
                :column-defs="colDefs"
                :row-data="props.allTableData"
                :tooltip-interaction="true"
                :tooltip-mouse-track="true"
                :tooltip-show-delay="500"
                tooltip-show-mode="whenTruncated"
                :autoSizeStrategy="sizingStrategy"
                :sideBar="sideBar"
                :getRowClass="getRowClass"
                :is-external-filter-present="shouldApplyQuickFilter"
                :does-external-filter-pass="applyQuickFilter"
                :sorting-order="['desc', 'asc', null]"
                @grid-ready="gridInit"
            />
        </div>
    </div>
</template>

<script setup>
// Global
import { onMounted, ref, inject, computed, watch, nextTick, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useEntityStore, useArenaOutputStore, useDatasetStore } from '@/stores'
import { useAuth } from '@/auth/authPlugin'
import { useFlag } from '@unleash/proxy-client-vue'

// local
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { dateComparator } from '@/components/moatTable/helpers/compare.js'
import { formatTpeRange } from '@/lib/financial.js'
import { cloneDeep } from 'lodash-es'

// injectors
const eventBus = inject('eventBus')
const { t } = useI18n()
const logger = inject('logger')
const entityStore = useEntityStore()
const arenaOutputStore = useArenaOutputStore()
const datasetStore = useDatasetStore()
const filters = inject('filters')
const { user } = useAuth()
const showArenas = useFlag('ipAlpha.boostCompetitorRank')
const enhancedContendersView = useFlag('ipAlpha.enhancedContendersFormatting')

// Refs/Props
const props = defineProps({
    tableData: {
        type: Array,
        default: () => [],
    },
    allTableData: {
        type: Array,
        default: () => [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
    everythingDoneLoading: {
        type: Boolean,
        default: false,
    },
    activeFilter: {
        type: String,
        default: 'all',
    },
    pinnedEntities: {
        type: Array,
        default: () => [],
    },
})

const emit = defineEmits([
    'allow-regenerate',
    'update-competitor-table-data',
    'groom-data',
    'get-competitors-from-watchlist',
    'refresh-competitor-table',
])

const gridApi = ref(null)
const updater = ref(0)
const sideBar = ref(null)
const filterUpdater = ref(0)
const loadingCompetitorData = ref(false)
const loadingWatchlists = ref(true)
const quickFilterOptions = ref([
    {
        itemText: 'All',
    },
    {
        itemText: 'Public',
    },
    {
        itemText: 'Private',
    },
    {
        itemText: 'Gov/Edu',
    },
    {
        itemText: 'Watchlist',
        arrowSide: 'right',
        showSubItems: false,
        items: [],
    },
    {
        itemText: 'Pinned',
    },
])
var externalFilterValue = 'All'
const searchTerm = ref(null)
const themeClass = ref('ag-theme-quartz')
const sizingStrategy = ref(
    enhancedContendersView.value
        ? {}
        : {
              type: 'fitProvidedWidth',
              width: 2000,
          }
)
const colDefs = ref(
    enhancedContendersView.value
        ? /* Updated contenders columns https://app.clickup.com/t/9014180224/MOAT-2212 */
          [
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.companyName'),
                      headerName: 'Company Name',
                      showFilterButton: true,
                  },
                  cellRenderer: 'MTCustomCellCompany',
                  cellRendererParams: {
                      allowNewTab: true,
                      tabOnLeft: true,
                  },
                  field: 'aonEntityName',
                  headerName: 'Company Name',
                  headerTooltip: 'Company Name',
                  minWidth: 400,
                  flex: 1,
                  sortingOrder: ['asc', 'desc', null],
                  filter: 'agTextColumnFilter',
                  pinned: 'left',
              },
              {
                  field: 'competitorRank',
                  headerName: 'Rank',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${p.data.competitorRank.toLocaleString()}`
                      }
                  },
                  headerTooltip: 'Rank',
                  minWidth: 100,
                  flex: 1,
                  sortingOrder: ['asc', 'desc', null],
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.innovationScore'),
                      headerName: 'Innovation Score',
                  },
                  field: 'powerScore',
                  headerName: 'Innovation Score',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${parseInt(p.data.powerScore.toFixed(0)).toLocaleString()}`
                      }
                  },
                  headerTooltip: 'Innovation Score',
                  minWidth: 200,
                  flex: 1,
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.opportunity'),
                      headerName: 'Momentum',
                  },
                  cellRenderer: 'MTCustomCellScoreChange',
                  cellRendererParams: {
                      lowerBound: 40,
                      upperBound: 50,
                      tooltip: {
                          increasing: 'Increasing Innovation',
                          decreasing: 'Decreasing Innovation',
                          steady: 'Steady Innovation',
                      },
                  },
                  field: 'opportunity',
                  headerName: 'Momentum',
                  headerTooltip: 'Momentum',
                  flex: 1,
                  minWidth: 150,
                  filter: 'agNumberColumnFilter',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${Math.round(p.data.opportunity)}`
                      }
                  },
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.percentOfPortfolio'),
                      headerName: 'Focus',
                  },
                  field: 'portfolioFocusPercentage',
                  headerName: 'Focus',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${p.data.portfolioFocusPercentage.toFixed(0)}%`
                      }
                  },
                  headerTooltip: 'Focus',
                  flex: 1,
                  minWidth: 120,
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.innImpliedPE'),
                      headerName: 'II P/E',
                  },
                  field: 'tpe',
                  headerName: 'II P/E',
                  headerTooltip: 'Innovation Implied P/E',
                  flex: 1,
                  minWidth: 120,
                  valueFormatter: (p) => {
                      if (p.data) {
                          return formatTpeRange(p.data.tpe, p.data.tpeLb, p.data.tpeUb)
                      }
                  },
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.innovationStart'),
                      headerName: 'Innovation Start',
                  },
                  valueGetter: (p) => {
                      if (p.data) {
                          return filters.toUTCString(p.data.entryPriorityDt) === '1-1-1'
                              ? '---'
                              : new Date(p.data.entryPriorityDt).getFullYear()
                      }
                  },
                  field: 'entryPriorityDt',
                  headerName: 'Innovation Start',
                  headerTooltip: `Innovation Start`,
                  comparator: dateComparator,
                  flex: 1,
                  minWidth: 200,
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.fiveYear'),
                      headerName: 'Expiring Soon',
                  },
                  field: 'fiveYearExpirePercent',
                  headerName: 'Expiring Soon',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${p.data.fiveYearExpirePercent.toFixed(0)}%`
                      }
                  },
                  headerTooltip: 'Expiring Soon',
                  flex: 1,
                  minWidth: 170,
                  filter: 'agNumberColumnFilter',
              },
              {
                  field: 'hqCountryName',
                  headerName: 'HQ Country',
                  hide: true,
                  filter: 'agSetColumnFilter',
              },
              {
                  field: 'totalRevenue',
                  headerName: 'Revenue',
                  hide: true,
                  filter: 'agNumberColumnFilter',
              },
              {
                  field: 'employeeCount',
                  headerName: 'Employee Count',
                  hide: true,
                  filter: 'agSetColumnFilter',
              },
              {
                  field: 'priceToEarnings',
                  headerName: 'Price to Earnings',
                  hide: true,
                  filter: 'agNumberColumnFilter',
              },
          ] /* Original contenders view */
        : [
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.companyName'),
                      headerName: 'Company Name',
                  },
                  cellRenderer: 'MTCustomCellCompany',
                  cellRendererParams: {
                      allowNewTab: true,
                      tabOnLeft: true,
                  },
                  field: 'aonEntityName',
                  headerTooltip: 'Company Name',
                  width: 300,
                  sortingOrder: ['asc', 'desc', null],
                  filter: 'agTextColumnFilter',
              },
              {
                  field: 'competitorRank',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${p.data.competitorRank.toLocaleString()}`
                      }
                  },
                  headerTooltip: 'Rank',
                  width: 100,
                  sortingOrder: ['asc', 'desc', null],
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.innImpliedPE'),
                      headerName: 'II P/E',
                  },
                  field: 'tpe',
                  headerTooltip: 'Innovation Implied P/E',
                  width: 70,
                  valueFormatter: (p) => {
                      if (p.data) {
                          return formatTpeRange(p.data.tpe, p.data.tpeLb, p.data.tpeUb)
                      }
                  },
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.innovationScore'),
                      headerName: 'Innovation Score',
                  },
                  field: 'powerScore',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${parseInt(p.data.powerScore.toFixed(0)).toLocaleString()}`
                      }
                  },
                  headerTooltip: 'Innovation Score',
                  width: 100,
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.opportunity'),
                      headerName: 'Momentum',
                  },
                  field: 'opportunity',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return Math.round(p.data.opportunity)
                      }
                  },
                  headerTooltip: 'Momentum',
                  width: 100,
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.coverage'),
                      headerName: 'Scope',
                  },
                  field: 'coverage',
                  headerTooltip: 'Scope',
                  width: 70,
                  valueFormatter: (p) => {
                      if (p.data) {
                          return p.data.coverage.toFixed(0)
                      }
                  },
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.percentOfPortfolio'),
                      headerName: 'Percentage of Portfolio',
                  },
                  field: 'portfolioFocusPercentage',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${p.data.portfolioFocusPercentage.toFixed(2)}%`
                      }
                  },
                  headerTooltip: 'Percentage of Portfolio',
                  width: 200,
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.fiveYear'),
                      headerName: 'Five Year Expire Percent',
                  },
                  field: 'fiveYearExpirePercent',
                  valueFormatter: (p) => {
                      if (p.data) {
                          return `${p.data.fiveYearExpirePercent}%`
                      }
                  },
                  headerTooltip: 'Five Year Expire Percent',
                  width: 200,
                  filter: 'agNumberColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t('productAlignment.definitionTooltips.priority'),
                      headerName: 'Priority Date',
                  },
                  valueGetter: (p) => {
                      if (p.data) {
                          return filters.toUTCString(p.data.entryPriorityDt) === '1-1-1'
                              ? '---'
                              : filters.toUTCString(p.data.entryPriorityDt)
                      }
                  },
                  headerTooltip: `Priority Date`,
                  comparator: dateComparator,
                  width: 150,
                  filter: 'agDateColumnFilter',
              },
              {
                  headerComponent: 'MTCustomColHeader',
                  headerComponentParams: {
                      tooltipDefintion: t(
                          'productAlignment.definitionTooltips.avgFilingDateContender'
                      ),
                      headerName: 'Average Filing Date',
                  },
                  valueGetter: (p) => {
                      if (p.data) {
                          return filters.toUTCString(p.data.averageFilingDt) === '1-1-1'
                              ? '---'
                              : filters.toUTCString(p.data.averageFilingDt)
                      }
                  },
                  headerTooltip: `Average Filing Date`,
                  comparator: dateComparator,
                  width: 150,
                  filter: 'agDateColumnFilter',
              },
          ]
)
const previousTabType = ref(null)

onMounted(() => {
    getUserWatchLists()
})

onBeforeUnmount(() => {
    eventBus.all.clear()
})

watch(
    () => props.allTableData,
    (newVal, oldVal) => {
        if (
            newVal?.find((n) => n.isCompetitor) &&
            !quickFilterOptions.value.find((n) => n.itemText === 'Direct Competitors')
        ) {
            quickFilterOptions.value.splice(1, 0, { itemText: 'Direct Competitors' })
        }
    },
    { immediate: true }
)

const loadingTable = computed(() => {
    return props.loading || loadingCompetitorData.value
})

const gridInit = (params) => {
    gridApi.value = params.api
    loadingWatchlists.value = false
    if (enhancedContendersView.value) {
        sideBar.value = {
            toolPanels: [
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                },
            ],
        }
    }
}

const getRowClass = (params) => {
    if (entityStore.entity && params.data.aonEntityName === entityStore.entity.name) {
        return 'target-entity'
    }
    if (
        arenaOutputStore.targetCompany.length > 0 &&
        arenaOutputStore.targetCompany[0].aonEntityName === params.data.aonEntityName
    ) {
        return 'target-entity'
    } else {
        return ''
    }
}

// search filter
const searchTable = () => {
    gridApi.value.setGridOption('quickFilterText', searchTerm.value)
}

const clearSearch = () => {
    searchTerm.value = ''
    searchTable()
}

// Quick filter
const shouldApplyQuickFilter = () => {
    // if externalFilterValue is not 'All', then we are filtering
    return externalFilterValue !== 'All'
}

const changeQuickFilterValue = (tab) => {
    if (tab.type === 'watchlist' || previousTabType.value === 'watchlist') {
        var params = {}
        if (tab.type === 'watchlist') {
            params = {
                watchlistPk: tab.itemValue,
            }
        }
        emit('refresh-competitor-table', params)
    }

    if (tab.type !== 'watchlist') {
        externalFilterValue = tab.itemText
        setTimeout(() => {
            gridApi.value.onFilterChanged()
        }, 1000)
    }
    previousTabType.value = tab.type
}

const applyQuickFilter = (node) => {
    if (node.data) {
        switch (externalFilterValue) {
            case 'Public':
                return node.data.entityType === 'Public Company'
            case 'Private':
                return (
                    node.data.entityType !== 'Government' &&
                    node.data.entityType !== 'College/University' &&
                    node.data.entityType !== 'Public Company'
                )
            case 'Gov/Edu':
                return (
                    node.data.entityType === 'Government' ||
                    node.data.entityType === 'College/University'
                )
            case 'Direct Competitors':
                return node.data.isCompetitor
            case 'Pinned':
                return props.pinnedEntities.includes(node.data.aonEntityPk)
            default:
                return true
        }
    }
    return true
}

const getUserWatchLists = async () => {
    loadingWatchlists.value = true

    try {
        let callData = await datasetStore.getDatasets([], user.value.id)
        if (callData && callData.results) {
            let results = callData.results.map((item) => {
                return {
                    itemText: item.dataset_name,
                    itemValue: item.dataset_pk,
                    itemDesc: item.dataset_description,
                    type: 'watchlist',
                }
            })
            quickFilterOptions.value
                .find((item) => item.itemText === 'Watchlist')
                .items.push(...results)
        }
    } catch (err) {
        eventBus.emit('snacktime', {
            type: 'error',
            message: 'Failed to Load competitor Watchlist',
        })
    } finally {
        filterUpdater.value++
        loadingWatchlists.value = false
    }
}
</script>

<style lang="scss" scoped>
.competitors-output-table {
    width: 100%;
    min-height: 400px;
    position: relative;

    :deep(.target-entity) {
        background: $grey03 !important;
        *:not:deep(.ticker) {
            color: white !important;
        }
        //color: white !important;

        .link {
            color: white !important;
        }
    }

    :deep(.aon-dropdown) {
        ul {
            max-height: 225px !important;
        }
    }
}
</style>
