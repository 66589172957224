<template>
    <div class="violin-chart">
        <div id="amChartViolin" ref="amChartViolin"></div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
import { useMoat2ProductStore } from '@/stores'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

import {
    dataPlum,
    dataTeal,
    dataBlue,
    dataAqua,
    dataCobalt,
    dataMarine,
    dataMagenta,
    dataRaspberry,
    dataOrange,
    dataYellow,
    dataLime,
    dataGreen,
} from '@/styles/js_variables.module.scss'

const moat2ProductStore = useMoat2ProductStore()
const props = defineProps({
    chartData: {
        type: Array,
        default: () => [],
    },
})

let root
let chart
let xAxis
let xData
let competitors

const setColors = computed(() => {
    let baseColors = [
        dataTeal,
        dataPlum,
        dataBlue,
        dataRaspberry,
        dataOrange,
        dataYellow,
        dataLime,
        dataGreen,
        dataMagenta,
        dataMarine,
        dataCobalt,
        dataAqua,
    ]
    return baseColors
})

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartViolin')
    configureData()
})

onBeforeUnmount(() => {
    root.dispose()
})

const configureData = async () => {
    xData = processData()
    competitors = moat2ProductStore.productAlignmentTopCompetitors.map((comp, index) => {
        return {
            company: comp.aonEntityName,
            color: setColors.value[index],
        }
    })
    configureChart()
}

const configureChart = () => {
    if (!root) {
        return
    }
    root.setThemes([am5themes_Animated.new(root)])
    chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: 'panX',
            wheelY: 'zoomX',
            maxTooltipDistance: 0,
        })
    )
    generateXAxes()

    competitors.forEach((competitor) => {
        createSeries(competitor)
    })

    chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
            behavior: 'zoomXY',
            xAxis: xAxis,
        })
    )
    chart.appear(1000, 100)
}
const generateXAxes = () => {
    xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'year',
            renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 20,
            }),
            tooltip: am5.Tooltip.new(root, {}),
        })
    )

    let xRenderer = xAxis.get('renderer')

    xRenderer.labels.template.setAll({
        rotation: -45,
        location: 0.5,
        multiLocation: 0.5,
        centerX: am5.p100,
        centerY: am5.p50,
        fontSize: 12,
    })

    xRenderer.grid.template.setAll({
        location: 0.5,
        multiLocation: 0.5,
    })

    xAxis.data.setAll(xData)

    chart.leftAxesContainer.setAll({
        layout: root.verticalLayout,
    })
}

const createSeries = (competitor) => {
    let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            maxDeviation: 0,
            strictMinMax: true,
            extraMin: 0.05,
            extraMax: 0.05,
            renderer: am5xy.AxisRendererY.new(root, {}),
        })
    )

    let yRenderer = yAxis.get('renderer')

    yRenderer.labels.template.setAll({
        forceHidden: true,
    })

    let series = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'high',
            openValueYField: 'low',
            categoryXField: 'year',
            fill: am5.color(competitor.color),
            tooltip: am5.Tooltip.new(root, {}),
        })
    )

    series
        .get('tooltip')
        .label.set(
            'text',
            `Company: [bold]${competitor.company}[/]\nInnovation Score: [bold]{count}[/]`
        )

    series.fills.template.setAll({
        fillOpacity: 1,
        visible: true,
    })

    series.data.setAll(processData(competitor.company))
}
const processData = (aonEntityName = null) => {
    const filteredData = aonEntityName
        ? props.chartData.filter((item) => item.aonEntityName === aonEntityName)
        : props.chartData

    return filteredData
        .reduce((acc, curr) => {
            const existingYear = acc.find((item) => item.filingYear === curr.filingYear)
            if (existingYear) {
                existingYear.count += curr.powerScore
            } else {
                acc.push({
                    filingYear: curr.filingYear,
                    count: curr.powerScore,
                })
            }
            return acc
        }, [])
        .map((item) => ({
            year: item.filingYear.toString(),
            count: Math.ceil(item.count),
            high: Math.ceil(item.count / 2),
            low: Math.ceil(item.count / 2) * -1,
        }))
}
</script>

<style lang="scss" scoped>
.violin-chart {
    width: 100%;
    height: 100%;
    position: relative;

    #amChartViolin {
        width: 100%;
        height: 100%;

        div {
            height: 100%;
        }
    }
}
</style>
